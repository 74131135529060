// AddCategoryDrwr.tsx
import React, { useEffect, useState } from 'react';
import { Button, Form, message} from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import { WebApi } from 'shared/WebApi';
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtImage from 'components/websitecomponents/TdtImage';
import { Col, Row } from 'react-bootstrap';

const projectpath = process.env.REACT_APP_API_URL;

interface TdtBannerDrwerprops {
  open?: boolean;
  onClose?: () => void;
  title?: string;
  destroyOnClose?: boolean;
  placement?: string;
}

const DrwrAddBanner: React.FC<TdtBannerDrwerprops> = (props) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  const [uploadfile, setuploadfile] = useState<File | null>(null);
  const [allBanners, setAllBanners] = useState<any[]>([]);
  // debugger
  const onFinish = async (values: any) => {   
    values.image = uploadfile;    
    try {
      const response = await WebApi('post', 'api/insert_banner', values, 'multipart');  
      const responseData: any = response; 
      if (responseData.status === 200) {  
        message.success('Banner added');
        getBanners(); 
      } else {
        console.error('Error: Request error');  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    }
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };
  
  const getBanners = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_banners');
      const responseData: any = response;
      if (responseData.status === 200) {
        const modifiedData = responseData.data;  
        setAllBanners(modifiedData.data);
        // debugger
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };
  
  const deleteBanner = async (banner_id: any) => {
    try { 
      const response = await WebApi('post', 'api/delete_banner', { banner_id: banner_id });
      const responseData: any = response; 
      debugger
      if (responseData.status === 200) { 
        message.success('Banner deleted successfully');
        getBanners();  
      }
    } catch (error:any) {
      console.error('Error:'+error);  
    } 
  };

  useEffect(() => {
    if (props.open) {
      getBanners();
    }
  }, [props.open]);

  const handleCompanyLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setuploadfile(file);   
  };

  const columns = [
    {
      title: 'Heading',
      dataIndex: 'heading',
      key: 'heading', 
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description', 
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_:any, record:any) => (
        <TdtImage src={`${projectpath}public/panelassets/images/uploads/${record.image}`} preview={true} width={'60px'}/>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_:any, record:any) => (
        <a onClick={() => deleteBanner(record.bannerId)}>Delete</a>
      ),
    },
  ];

  return (
    <TdtDrawer title={props.title} open={props.open} placement={props.placement} onClose={props.onClose} width={'1050px'} destroyOnClose={props.destroyOnClose}>
      <Form
        name="add_banner"
        form={form}
        ref={formRef} 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      > 
        <Row>
          <Col sm={12} md={4}>
            <TdtInput label='Heading' name='heading' />
          </Col> 
          <Col sm={12} md={4}>
            <TdtInput label='Description' name='description' />
          </Col> 
          <Col sm={12} md={4} className='d-flex flex-column'> 
            <label className='customlabel'>Banner Image</label>
            <input type="file" onChange={handleCompanyLogoChange} />
          </Col>  
          <Col sm={12} md={12}>
            <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5'/> 
          </Col>
        </Row>
      </Form> 
      <Row>
        <Col sm={12}>
          <TdtTable pagination={false} tblheadcolumns={columns} tabledata={allBanners} />  
        </Col>  
      </Row>
    </TdtDrawer>
  );
};

export default DrwrAddBanner;
