import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from "../../assets/images/deluxetravellogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import { WebApi } from 'shared/WebApi'; 
import { useNavigate } from 'react-router-dom';

interface Homeprops {
className?: string
}

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
): MenuItem {
    return {
        key,
        icon,
        label,
    } as MenuItem;
}

const itemsMenuAdmin: MenuItem[] = [
    
    getItem(<Link to="https://thedeluxetravel.com/tours/category/91"> Desert Safari  </Link>, ' Desert Safari '),
    getItem(<Link to="https://thedeluxetravel.com/tours/category/93"> Sight Seeing </Link>, ' Sight Seeing  '),
    getItem(<Link to="https://thedeluxetravel.com/tours/category/100"> Private Tours </Link>, ' Private Tours '),
    getItem(<Link to="https://thedeluxetravel.com/tours/category/102"> Executive </Link>, ' Executive'),  
    getItem(<Link to="https://thedeluxetravel.com/tours/category/102"> Dhow Cruise </Link>, ' Dhow Cruise '),  
    {
    key: 'sub1',
    label: 'Others', 
    className:'othermenu',
    children: [
        { key: '5', label: 'Theme Park' },
        { key: '6', label: 'Sky Tours' },
        { key: '7', label: 'Sea Advantures' },
        { key: '8', label: 'Quad Biking' },
    ],
    }, 
     
];


export const HeaderNavbar: React.FunctionComponent<Homeprops> = (props) => {
    
    return (
        <Header className={`setheaderweb ${props.className}`}>
            <Container fluid className='p-0'>
            <Container>
                    <Row> 
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={itemsMenuAdmin} style={{ flex: 1, minWidth: 0 }} className='headertours' />
                    </Row>  
                </Container >
            </Container >

        </Header>
    )
}

export default HeaderNavbar; 