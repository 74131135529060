import React, { useEffect, useState } from 'react';  
import TdtBannerCauroselTravel from 'components/websitecomponents/TdtBannerCauroselTravel';   
import ReasonsToWork from 'components/websitecomponents/ReasonsToWork';
import TdtCauroselPackage from 'components/websitecomponents/TdtCauroselPackage'; 
import TdtTravelPkg from 'components/websitecomponents/TdtTravelPkg';
import { Container, Row, Col } from 'react-bootstrap'; 
import TdtToursPackage from 'components/websitecomponents/TdtToursPackage';
import TdtWhoWeAre from 'components/websitecomponents/TdtWhoWeAre';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { HiClock, HiOutlineChatAlt, HiSaveAs, HiShieldExclamation, HiStar, HiSupport } from 'react-icons/hi';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtFaqs from 'components/websitecomponents/TdtFaqs';
import { WebApi } from 'shared/WebApi';
import { Divider } from 'antd';
import TdtImage from 'components/websitecomponents/TdtImage';
import ctahomeim from "../../src/assets/images/ctahomes.png";

import footerbg from "../../src/assets/images/footerbg.png";

const projectpath = process.env.REACT_APP_API_URL;



interface Homeprops { 
}

const bannerdescription = [
    {
        img: process.env.PUBLIC_URL  + 'assets/banner-img-1.png',
        title: "Dubai Tour",
        description: "Best Toure guid in Dubai Love this Whole Day Visit Great"
    },
    {
        img: process.env.PUBLIC_URL  + 'assets/banner-img-2.png',
        title: "Sharja Visit",
        description: "Nice Place More Visits Best Toure guid in Dubai Love this Whole Day Visit Great"
    },
    {
        img: process.env.PUBLIC_URL  + 'assets/banner-img-1.png',
        title: "Abu Dhabi",
        description: "Great and Best of Best Toure guid in Dubai Love this Whole Day Visit Great"
    }
]; 



const adventureindubai = [
  {
      img: process.env.PUBLIC_URL  + 'assets/tra7.png',
      title: "Places to visit in Dubai 4", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra8.png',
      title: "Places to visit in Dubai 5", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra9.png',
      title: "Places to visit in Dubai 6", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra10.png',
      title: "Places to visit in Dubai 7", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra11.png',
      title: "Places to visit in Dubai 8", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra12.png',
      title: "Places to visit in Dubai 9", 
  },
]; 

const reasonsToWork = [ 
    {
        reasonsToWorklable: 'BEST PRICE GUARANTEE'       
    },
    {
        whyyiconlabel: <TdtIcon icon={<HiOutlineChatAlt />} label='24X7 LIVE CHAT SUPPORT' classNamelinkdiv='seticonhome' classNameicon='seticonhomewhy' classNamelbl='setlblhomewhy'/>         
    },
    {
        whyyiconlabel: <TdtIcon icon={<HiOutlineChatAlt />} label='FAST BOOKING' classNamelinkdiv='seticonhome' classNameicon='seticonhomewhy' classNamelbl='setlblhomewhy'/>       
    },
    {
        whyyiconlabel: <TdtIcon icon={<HiOutlineChatAlt />} label='5 STAR FACILITIES' classNamelinkdiv='seticonhome' classNameicon='seticonhomewhy' classNamelbl='setlblhomewhy'/>       
    },
    {
        whyyiconlabel: <TdtIcon icon={<HiOutlineChatAlt />} label='WIFI COMING SOON' classNamelinkdiv='seticonhome' classNameicon='seticonhomewhy' classNamelbl='setlblhomewhy'/>
    }
]; 


const reasonsToWorks = {
    "headerContent": {
      "mainText": "Reasons to",
      "spanText": "Book With Us"
    },
    "iconData": [
      {
        "icon": <TdtIcon icon={<HiShieldExclamation />}/>,
        "label": "BEST PRICE GUARANTEE", 
      },
      {
        "icon": <TdtIcon icon={<HiSupport />}/>,
        "label": "24X7 LIVE CHAT SUPPORT", 
      },
      {
        "icon": <TdtIcon icon={<HiSaveAs />}/>,
        "label": "FAST BOOKING", 
      },
      {
        "icon": <TdtIcon icon={<HiStar />}/>,
        "label": "5 STAR FACILITIES", 
      },
      {
        "icon": <TdtIcon icon={<HiClock />}/>,
        "label": "WIFI COMING SOON", 
      },
     
    ]
  } 

export const Home: React.FunctionComponent<Homeprops> = (props) => { 

  const [getAllBanners, setGetAllBanners] = useState<any[]>([]);  
  const getBanners = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_banners');
      const responseData: any = response;
      if (responseData.status === 200) {  
        const modifiedData:any = responseData.data.data  
        setGetAllBanners(modifiedData);  
      }
    } catch (error:any) {
      console.error('Error:'+ error);  
    } 
  }; 
  

  // const [getAllReasons, setGetAllReasons] = useState<any[]>([]);  
  // const getReasons = async () => {   
  //   try {
  //     const response = await WebApi('get', 'api/get_all_reasons');
  //     const responseData: any = response;
  //     if (responseData.status === 200) {  
  //       const modifiedData:any = responseData.data.data  
  //       setGetAllReasons(modifiedData);   
  //     }
  //   } catch (error:any) {
  //     console.error('Error:'+ error);  
  //   } 
  // }; 

  
 

  const [getAllTopRatedPackages, setAllTopRatedPackages] = useState<any[]>([]);  
  const getTopRatedPackages = async () => {   
    try {
      const response = await WebApi('get', 'api/get_top_package');
      const responseData: any = response;
      if (responseData.status === 200) {  
        const modifiedData:any = responseData.data.data  
        setAllTopRatedPackages(modifiedData);  
         
      }
    } catch (error:any) {
      console.error('Error:'+ error);  
    } 
  }; 
  useEffect(() => {
    getBanners();
    // getReasons();
    getTopRatedPackages();
    getMoreInformation();
  }, []);

  const [getMoreinfor, setGetMoreinfor] = useState<string>(''); 
 
 
  const getMoreInformation = async () => {   
    try {
      const response = await WebApi('get', 'api/get_moreinfo');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data  
        setGetMoreinfor(modifiedData[0].information);
         
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 



  return (  
      <React.Fragment>   
          <TdtBannerCauroselTravel bannerdescription={getAllBanners} />
          <ReasonsToWork   /> 
          <Container  className=''>
            <Row>
              <Col className="d-flex justify-content-between">
                  <h5 className="setheadingrow hightlight">Top Rated</h5>
                  <h5 className="setheadingrow">The Deluxe Travel</h5>
                  {/* <TdtButton label="View All" href="#" /> */}
              </Col>
            </Row>   
          </Container>
          <TdtCauroselPackage adventureindubai={getAllTopRatedPackages} dots={true} infinite={true} speed={500} slidesToShow={4} slidesToScroll={1} />  
          
          <TdtTravelPkg/>
        
          {/* <TdtCarouselPopular />  */}
          <TdtToursPackage />
          <TdtWhoWeAre /> 
          <Container fluid className='position-relative cta-home p-0' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/footerbg.png'})`, backgroundPosition:'center'}}> 
            <TdtImage preview={false} src={ctahomeim}/>
            <div className='position-absolute d-flex gap-4'>
              <h5>Travel With Us</h5> <TdtButton label="Contact Us" classNamebtn='hmctabtn' href="https://thedeluxetravel.com/contact-us" /> <h5>The Deluxe Travel</h5>
            </div>
          </Container>
          <TdtFaqs/>  

          <Container fluid>
            <Container>
              <Row>
                <Col>
                  <div className='border box-shadow p-5 mb-5'
                      dangerouslySetInnerHTML={{
                        __html: getMoreinfor,
                      }}
                    />  
                </Col>
              </Row>
            </Container>
          </Container>
          
      </React.Fragment>
  )
} 
export default Home;




