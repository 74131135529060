import { Avatar, Badge, Button, Form, Input, InputNumber } from 'antd';
import form, { FormInstance } from 'antd/es/form';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment';

import { NavLink } from 'react-router-dom';
import TdtDate from 'components/websitecomponents/TdtDate';
import { Col, Row } from 'react-bootstrap';



import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import TdtNumberAddon from 'components/websitecomponents/TdtNumberAddon';
import TdtTable from 'components/websitecomponents/TdtTable';
import TdtButton from 'components/websitecomponents/TdtButton';
import { WebApi } from 'shared/WebApi';

type TableRowSelection<T> = TableProps<T>['rowSelection'];

interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  pid?:string
}

interface DataType {
  key: any;
  tour_option: string;
  price: string;
  qty_adults: string;
  qty_childern: string;
  addon_price: string;
}
interface AdditionalOption {
  title: string;
  price: number;
  persons: number;
} 
interface PackageDataType {
  pid: number;
  cat_id: number;
  cat_title: string;
  title: string;
  description: string;
  price: number;
  discount_price: number;
  child_price: number;
  child_discount_price: number;
  duration_type: string;
  duration: string;
  rating: number;
  package_image: string;
  package_image_thumbnail: string;
  transfer_type: string;
  transfer_type_adultprice: number;
  transfer_type_childprice: number;
  tags: string;
  image: string;
  opt_title: string;
  opt_price: number;
  opt_persons: number;
  long_description:string;
  SlideImages: string[];  
  AdditionalOptions: { [key: string]: AdditionalOption }; 
} 



const tabledata: DataType[] = [];
for (let i = 0; i < 10; i++) {
  tabledata.push({
    key: i,
    tour_option: `Tour Option `,
    price: `AED 100 for ${i} Person(s)`,
    qty_adults: `qty_adults. `,
    qty_childern: `qty_childern. `,
    addon_price: `addon_price. `,
  });
}

const TdtBookDrwer: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  }; 

 
 // 

 
 const [numberOfAdults, setNumberOfAdults] = useState(2);
 const [numberOfChilderns, setNumberOfChilderns] = useState(0);
 
 const numberOfAdultsFun = (e: any) => { 
  setNumberOfAdults(e);
  console.log("Adult: "+numberOfAdults)
}
const numberOfChildernsFun = (e: any) => { 
  setNumberOfChilderns(e);
  console.log("children:"+numberOfChilderns)
}


//  
const [numberOfAddonsAdults, setNumberOfAddonsAdults] = useState(1);
const [numberOfAddonsChilderns, setNumberOfAddonsChilderns] = useState(1);  

const numberOfAddonsAdultsFun = (e: any) => { 
  setNumberOfAddonsAdults(e);
  console.log("Adult: "+numberOfAddonsAdults)
}
const numberOfAddonsChildernsFun = (e: any) => { 
  setNumberOfAddonsChilderns(e);
  console.log("children:"+numberOfAddonsChilderns)
}


const [packageData, setPackageData] = useState<PackageDataType | null>(null); 
const getPackageById = async () => {   
  try {
    const response = await WebApi('get', `api/get_package_byId/${props.pid}`); 
    const responseData: any = response;
    if (responseData.status === 200) { 
      const modifiedData  = responseData.data.data; // Assuming this has the correct structure
      setPackageData(modifiedData);  
        
  } 
  } catch (error:any) {
    console.error('Error:', error);  
  } 
};

// 
const [tourTotal, setTourTotal] = useState(232);
const [addonTotal, setAddonTotal] = useState(323);
const [grandTotal, setGrandTotal] = useState(553);
const [addonPriceTotal, setAddonPriceTotal] = useState(553); 

const tblheadcolumnsDe: TableColumnsType<DataType> = [
    {
      title: 'Tour Option',
      dataIndex: 'tour_option',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Qty / Adults',
      dataIndex: 'qty_adults',  
      render: (_, record) => (
        <InputNumber min={0} max={22} defaultValue={record.key}  onChange={numberOfAddonsAdultsFun} name="costperchild" className='w-100'  />
      ) 
    },
    {
      title: 'Child(ern)',
      dataIndex: 'qty_childern',
      render: (record) =>  <InputNumber min={0} max={22} defaultValue={0}   onChange={numberOfAddonsChildernsFun} name="costperchild" className='w-100'  />    ,
    },
    {
      title: 'Addon Price',
      dataIndex: 'addon_price',
      render: (record) =>  addonPriceTotal   ,
   
    }
  ];



  const [data, setData] = useState([
    { key: 1, title: 'Item 1', price: 10, quantity: 0, result: 0 },
    { key: 2, title: 'Item 2', price: 20, quantity: 0, result: 0 },
    { key: 3, title: 'Item 3', price: 30, quantity: 0, result: 0 },
  ]);

  const handleQuantityChange = (value:any, key:any) => {
    const newData = data.map((item) => {
      if (item.key === key) {
        const newQuantity = Number(value);
        return {
          ...item,
          quantity: newQuantity,
          result: item.price * newQuantity,
        };
      }
      return item;
    });
    setData(newData);
  };

  const tblheadcolumns: TableColumnsType<DataType> = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <Input 
          min={0} 
          max={22} 
          type='number'
          defaultValue={0}   
          onChange={(e) => handleQuantityChange(e.target.value, record.key)} 
          name="costperchild" 
          className='w-100'  
        /> 
      ),
    },
    { title: 'Result', dataIndex: 'result', key: 'result' },
  ];
 
  const onFinish = (values: any) => { 
    // values.grand_amount = priceAdultTotal;
    values.start_date = moment(values.start_date).format("YYYY-MM-DD")

  }
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 

 const [sharingTransport, setSharingTransport] = useState<any[]>([]);  
  const options = [
      {
        value: 'shared',
        label: 'Shared',
      },
      {
        value: 'private',
        label: 'Private',
      }
    ] 

  // Table 
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); 
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  }; 

  
  useEffect(() => { 
  
}, [props.open]) 


  return (
        <TdtDrawer title={props.title}  open={props.open} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
             Addons for Adults:{numberOfAddonsAdults}
             Addons for Children:{numberOfAddonsChilderns}

             ss{props.pid}sss
            <Form
                name="add-designation-form"
                form={form}
                ref={formRef}
                // initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
              <Row>
                <Col sm={12} md={3}>
                  <TdtSelect
                      name="tour_transfer"
                      required={true}
                      requiredMessage="Tour Transfer is Required"
                      optionList={options}
                      value={sharingTransport}
                      onChange={(value:any) => setSharingTransport(value)}
                      filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                      showSearch={true}
                      label='Select tour Transfer' 
                      allowClear={true}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <TdtDate label="Select tour Date" name="start_date" className='w-100' required={true} requiredMessage="Start Date is Required" /> 
                </Col>
                <Col sm={12} md={3} className='d-flex flex-column'>
                  <label className='pb-2'>No of Adults {packageData?.price}</label>
                  <InputNumber min={2} max={22} defaultValue={2}   onChange={numberOfAdultsFun} name="costperadult" className='w-100'   />
                </Col>
                <Col sm={12} md={3} className='d-flex flex-column'> 
                  <label className='pb-2'>No of Child (Max 8 Yrs) @  {packageData?.child_price}</label>
                  <InputNumber min={0} max={22} defaultValue={0}   onChange={numberOfChildernsFun} name="costperchild" className='w-100'  />
                </Col>
                <Col sm={12} className='position-relative'> 
                    <h5>Customize Additional Inclusions</h5>
                    <TdtTable  pagination={false} tblheadcolumns={tblheadcolumns} tabledata={tabledata} /> 
                    <div className='d-flex justify-content-between tblfooterdetail'>
                      <div className='d-flex gap-4'>
                        <div className='tourtotal'>Tour Total: <span><b>{tourTotal}</b></span></div>
                        <div className='addontourtotal'>Addon-On Total: <span><b>{addonTotal}</b></span></div>
                        <div className='grandtourtotal'>Grand Total: <span><b>{grandTotal}</b></span></div>
                      </div>
                      <div>
                        <TdtButton href={'check-out'} label={'Add To Cart'}/>
                      </div>
                    </div>
                </Col>
              </Row>
            </Form>
        </TdtDrawer>
  );
}

export default TdtBookDrwer;