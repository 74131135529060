import { Divider, Layout, Menu, MenuProps } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import footerlogo from "../../assets/images/footerlogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import TdtListStyle from 'components/websitecomponents/TdtListStyle';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { BsFacebook, BsGoogle, BsInfoCircle, BsInstagram, BsLinkedin, BsTiktok, BsTwitter, BsYoutube } from 'react-icons/bs';
import TdtImage from 'components/websitecomponents/TdtImage';

import footerbg from "../../assets/images/footerbg.png";
import footerlogoTdt from "../../assets/images/logoWHITE.svg";
import { useSettings } from 'components/SettingsProvider';

const projectpath = process.env.REACT_APP_API_URL;

interface Footerareaprops {

}

const { Footer } = Layout;
 

export const FooterArea: React.FunctionComponent<Footerareaprops> = (props) => {

    const settings = useSettings(); // Use the settings context 
  

    const listdata = [ 
        <a href='http://thedeluxetravel.com/tours'> Tours</a>,
        <a href='http://thedeluxetravel.com/about-us'> About Us</a>,
        <a href='http://thedeluxetravel.com/gallery'> Gallery</a>,
        <a href='http://thedeluxetravel.com/contact-us'> Contact Us</a>, 
        <a href='http://localhost:3000/privacy-policy'> Privacy Policy</a>, 
    ]; 

    
    const listdata2 = [
        <a href='http://thedeluxetravel.com/tours/40'> Thrilling Red Dune Desert Safari</a>,
        <a href='http://thedeluxetravel.com/tours/41'> Dinner in Desert</a>,
        <a href='http://thedeluxetravel.com/tours/42'> Evening Safari with Quad Bike</a>,
        <a href='http://thedeluxetravel.com/tours/43'> Family Desert Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/44'> Morning Safari + Quad Bike</a>, 
    ]; 

    const listdata3 = [
        <a href='http://thedeluxetravel.com/tours/45'> Morning Safari + Quad Bike</a>,
        <a href='http://thedeluxetravel.com/tours/46'> Over night Desert Safari</a>,
        <a href='http://thedeluxetravel.com/tours/47'> Premium Desert Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/48'> Private Evening  Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/49'> Private Morning  Safari Dubai</a>, 
    ]; 

    const listdata4 = [
        <a href='http://thedeluxetravel.com/tours/49'> Thrilling Red Dune Safari + Bike</a>,
        <a href='http://thedeluxetravel.com/tours/54'> Hot Air Balloon</a>,
        <a href='http://thedeluxetravel.com/tours/55'> VIP Desert Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/57'> Sunrise View Safari</a>,
        <a href='http://thedeluxetravel.com/tours/58'> Camel Trekking Dubai</a>, 
    ]; 

    const listdata5 = [
        <a href='http://thedeluxetravel.com/tours/59'> VIP Desert Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/60'> Hatta Tour from Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/41'> Dinner in Desert</a>,
        <a href='http://thedeluxetravel.com/tours/43'> Family Desert Safari Dubai</a>,
        <a href='http://thedeluxetravel.com/tours/46'> Over night Desert Safari</a>, 
    ]; 


    return (
        <Footer style={{ backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/footerbg.png'})`, backgroundPosition:'center'}} className='footmain position-relative'>
         <div className='footr-content'> 
            <Container fluid className='footertopmain pt-5'>
                <Container >
                    <Row>
                        <Col>
                            <div className='col1menu1'>
                                <h6 className='text-light pb-2'>Quick Links</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='col2menu2'>
                            <h6 className='text-light pb-2'>UAE Activities</h6>
                                <TdtListStyle listdata={listdata2} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                       
                        <Col className='footrmenu3'> 
                            <Divider className='divider-footer' type='vertical'> </Divider>

                            <div className='col3menu1'>
                            <h6 className='text-light pb-2'>Desert Safari Tours</h6>
                                <TdtListStyle listdata={listdata3} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col> 
                            <div className='col4menu2'>
                            <h6 className='text-light pb-2'>Sightseeing Tours</h6>
                                <TdtListStyle listdata={listdata4} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col> 
                            <div className='col5menu2'>
                            <h6 className='text-light pb-2'>Best Selling Tours</h6>
                                <TdtListStyle listdata={listdata5} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='footermid text-center py-4'>
                <Container >
                    <Divider className='divider-footer'>
                        <TdtIcon icon={<BsFacebook/>} tolink={settings?.facebook}/>
                        <TdtIcon icon={<BsInstagram/>} tolink={settings?.instagram}/>
                        <TdtIcon icon={<BsYoutube/>} tolink={settings?.youtube}/>
                        <TdtIcon icon={<BsLinkedin/>} tolink={settings?.linkedin}/>
                        <TdtIcon icon={<BsTwitter/>} tolink={settings?.twitter}/>
                        <TdtIcon icon={<BsGoogle/>} tolink={`mailto:${ settings?.email_secondary}`}/>
                        <TdtIcon icon={<BsTiktok/>} tolink={settings?.tiktok}/>
                    </Divider>
                </Container>
                <Container className='copyrightcontrn mt-4'>
                    <Link to="http://thedeluxetravel.com/"><TdtImage preview={false}  src={projectpath +  "/public/panelassets/images/uploads/" + settings?.footer_logo }   classNameimg='w-sm-100'/> </Link>
                    <div className='text-light mt-4'>© 2020 - {new Date().getFullYear()}. All rights reserved by <Link to="http://thedeluxetravel.com/">thedeluxetravels.com</Link></div>
                </Container>
            </Container>
 
         </div> 
        <div className='ftroverlay'></div>
        </Footer>


    )
}

export default FooterArea; 